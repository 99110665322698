@use "../../shared";

.input {
    font-family: "Lexend", sans-serif;
    background-color: #FFFFFF;
    border: 2px shared.$secondary-color solid;
    padding: 7px;
    font-size: 13px;
    border-radius: 4px;
    transition: border-color .3s;
    caret-color: shared.$fg-color;
    font-weight: bold;
    &:focus {
        outline: none;
        border-color: shared.$fg-color;
    }
    @include shared.mobile() {
        font-size: 27px;
        padding: 20px;
        border-width: 5px;
    }
}
