@use "../../shared";

#home_page {
    text-align: center;
    @include shared.rails;
}
#logo {
}
.box {
    border: shared.$fg-color 2px solid;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 40px;
}
#error {
    color: red;
    margin: 20px;
}