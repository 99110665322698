@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');

$fg-color: #99794F;
$secondary-color: #EAC998;

$SMALL_DESKTOP_WIDTH: 1200px;
$MOBILE_WIDTH: 1000px;

body {
    font-family: "Lexend", sans-serif;
    color: $fg-color;
}

@mixin mobile() {
    @media ( max-width: $MOBILE_WIDTH ) {
        @content;
    }
}
@mixin small_desktop() {
    @media ( max-width: $SMALL_DESKTOP_WIDTH ) {
        @content;
    }
}

@mixin rails() {
    padding: 50px 30vw;
    @include mobile(){
        padding: 50px 2vw;
    }
    @include small_desktop(){
        padding: 50px 15vw;
    }
}

h1 {
    font-size: 25px;
    @include mobile() {
        font-size: 40px;
    }
}
h2 {
    font-size: 20px;
    @include mobile() {
        font-size: 30px;
    }
}
h3 {
    font-size: 17px;
    @include mobile() {
        font-size: 25px;
    }
}
p {
    font-size: 17px;
    @include mobile() {
        font-size: 29px;
    }
}
:global(.logo) {
    height: 20vh;
    max-width: 90vw;
    @include mobile() {
        height: 15vh;
    }
}
:global(.footer) {
    margin: 30px 0 15px 0;
    font-size: 13px !important;
    color: rgba($fg-color, 0.5);
    text-align: center;
    @include mobile() {
        font-size: 15px !important;
    }
}
