@use "../../shared";

#admin_page {
    text-align: center;
    @include shared.rails;
    & > h1 {
        font-size: 40px;
        margin-bottom: 0px;
    }
}
#logo {
    height: 20vh;
    max-width: 90vw;
}

.box {
    border: shared.$fg-color 2px solid;
    border-radius: 5px;
    padding: 20px;
    margin-top: 50px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: stretch;
    gap: 30px;
}

#error {
    color: red;
}
#success {
    color: green;
}
