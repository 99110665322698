@use "../../shared";

.hint_list {
    a {
        text-decoration: none;
        color: shared.$fg-color;
    }
}

.hint {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    padding: 10px;
    border-bottom: 2px solid shared.$fg-color;
    h2, p {
        margin: 0;
    }
    img, .icon {
        text-align: center;
        width: 10%;
        font-size: 30px;
    }
    .number {
        margin-right: 15px;
    }
    .info_box {
        width: 70%;
    }
    &.found {
        color: rgba(shared.$fg-color, 40%);
        img {
            opacity: 40%;
        }
    }
}

.hint_list a:last-child .hint {
    border-bottom: none;
}