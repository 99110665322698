@use "../../shared";

#discovery_page {
    text-align: center;
    @include shared.rails;
    & > h1 {
        font-size: 40px;
        margin-bottom: 0px;
        @include shared.mobile() {
            font-size: 60px;
        }
    }
    & > h2 {
        font-size: 25px;
        margin-top: 0px;
        margin-bottom: 50px;
        @include shared.mobile() {
            font-size: 40px;
        }
    }
}
#logo {
    height: 20vh;
    max-width: 90vw;
}
#error {
    color: red;
    margin: 20px;
}
.box {
    border: shared.$fg-color 2px solid;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
}
#submit_info_box {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 15px;
}