@use "../../shared";

.hint {
    white-space: pre-line;
    border: shared.$fg-color 2px solid;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 40px;
    img {
        max-width: 90%;
        max-height: 300px;
        border-radius: 8px;
    }
}
