@use "../../shared";


#view_hint_page {
    text-align: center;
    @include shared.rails;
    & > h1 {
        font-size: 40px;
        margin-bottom: 0px;
        @include shared.mobile() {
            font-size: 60px;
        }
    }
    & > h2 {
        font-size: 25px;
        margin-top: 0px;
        margin-bottom: 50px;
        @include shared.mobile() {
            font-size: 40px;
        }
    }
}
#logo {
    height: 20vh;
    max-width: 90vw;
}
#error {
    color: red;
    margin: 20px;
}

.report_missing {
    color: shared.$fg-color;
    text-decoration: none;
    svg {
        margin-right: 7px;
    }
}
