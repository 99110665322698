@use "../../shared";

.button {
    background-color: shared.$secondary-color;
    border-radius: 50px;
    padding: 10px 20px;
    color: shared.$fg-color;
    border: none;
    outline: none;
    font-size: 15px;
    cursor: pointer;
    text-align: center;
    font-family: "Lexend", sans-serif;
    display: inline-block;
    transition: background-color 0.2s;
    &:hover {
        background-color: darken(shared.$secondary-color, 5%);
    }
    &:active {
        background-color: darken(shared.$secondary-color, 10%);
    }
    svg {
        margin: 0 7px;
    }
    &.secondary {
        background: none;
    }
    @include shared.mobile() {
        font-size: 30px;
        padding: 20px 40px;
    }
}
